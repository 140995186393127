import { AfterViewInit, Component, ElementRef, Input, Renderer2, ViewChild } from '@angular/core';
import { BaseComponent } from '../BaseComponent';
import { ActivatedRoute, Router } from '@angular/router';
import { APP_ROUTES } from 'src/app/modules/routing/AppRoutes';
import { GroupService } from 'src/app/services/group-service/group.service';
import { isElementVisibleInViewport } from 'src/app/utility/helpers';

@Component({
  selector: 'll-category-nav-bar',
  templateUrl: './category-nav-bar.component.html',
  styleUrls: [
    './../../pages/layout.scss',
    './category-nav-bar.component.scss',
  ]
})
export class CategoryNavBarComponent extends BaseComponent implements AfterViewInit {
  @ViewChild('container') container!: ElementRef;

  @Input() selectedCategory: string | null = null;

  public ageHidden: boolean = false

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private groupService: GroupService
  ) {
    super();
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe(params => {
      this.ageHidden = params.get('age_hidden')?.toLowerCase() === 'true';
    });
  }

  ngAfterViewInit(): void {
    const targetElement = this.container.nativeElement.querySelector(`#${this.selectedCategory}`);
    const isVisible = isElementVisibleInViewport(targetElement);

    if (targetElement && !isVisible) {
      const targetRect = targetElement.getBoundingClientRect();
      const containerRect = this.container.nativeElement.getBoundingClientRect();
      const scrollLeft = targetRect.left - containerRect.left;

      this.renderer.setProperty(this.container.nativeElement, 'scrollLeft', scrollLeft);
    }
  }

  public selectCategory(page: string, category?: string): void {
    this.groupService.resetSelectedGroup();

    if (category) {
      this.router.navigate(['/', APP_ROUTES[page].value, category]);
    } else {
      this.router.navigate(['/', APP_ROUTES[page].value]);
    }
  }
}
