export enum COLORS {
    WHITE =                 'FFFFFF',
    BLACK =                 '#000000',
    LL_WHITE =              '#FCFCFC',
    LL_AQUA_DEEP =          '#245B60',
    LL_AQUA_SHALLOW =       '#2C6064',
    LL_AQUA_LIGHT =         '#396B6F',
    LL_AQUA_GREY =          '#6F9498',
    LL_GOLD =               '#CEBA8F',
    LL_RISK_YELLOW =        '#F1AE01',
    LL_RISK_GREEN_LIGHT =   '#89B811',
    LL_RISK_GREEN =         '#A6E700',
    LL_RISK_ORANGE =        '#FC5644',
    LL_RISK_RED =           '#C11806',
    LL_DODGER =             '#1887FD',
    LL_GREY =               '#B2B2B2'
}
