{
  "name": "leadlife-dashboard",
  "version": "4.1.1",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "build": "ng build",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "generatePeerComparisonItems": "node ./scripts/generatePeerComparisonItems.js $npm_config_path"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.12",
    "@angular/cdk": "^17.3.5",
    "@angular/common": "^18.2.12",
    "@angular/compiler": "^18.2.12",
    "@angular/core": "^18.2.12",
    "@angular/forms": "^18.2.12",
    "@angular/material": "^17.3.5",
    "@angular/platform-browser": "^18.2.12",
    "@angular/platform-browser-dynamic": "^18.2.12",
    "@angular/router": "^18.2.12",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "angularx-qrcode": "^17.0.0",
    "apexcharts": "^3.48.0",
    "hammerjs": "^2.0.8",
    "ng-apexcharts": "^1.10.0",
    "ngx-markdown": "^17.2.0",
    "rxjs": "~7.8.1",
    "tslib": "^2.6.2",
    "uuid": "^9.0.1",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.12",
    "@angular/cli": "^18.2.12",
    "@angular/compiler-cli": "^18.2.12",
    "@types/hammerjs": "^2.0.45",
    "@types/jasmine": "~3.10.0",
    "@types/node": "^20.12.7",
    "@types/qrcode": "^1.5.5",
    "@types/uuid": "^9.0.8",
    "csv-parser": "^3.0.0",
    "jasmine-core": "~4.0.0",
    "karma": "~6.3.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.1.0",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "~1.7.0",
    "typescript": "~5.4.5"
  }
}
