import { formatDate, formatNumber } from '@angular/common';
import { AfterViewInit, Component, ElementRef, Inject, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { ApexAxisChartSeries, ApexChart, ApexGrid, ApexLegend, ApexMarkers, ApexStroke, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
// Import ApexOptions to get ApexFill because of bug (ApexFill types from ng-apexcharts are
// not working as expected for the gradient) -> https://github.com/apexcharts/ng-apexcharts/issues/207
import { ApexOptions } from 'apexcharts';
import { COLORS } from 'src/app/constants/Colors';
import { DashboardResult, DashboardResultRiskLevel, DashboardResultValue } from 'src/app/model/DashboardResult';

@Component({
  selector: 'll-value-evolution',
  templateUrl: './value-evolution.component.html',
  styleUrls: ['./value-evolution.component.scss']
})
export class ValueEvolutionComponent implements OnInit, AfterViewInit {

  @Input("result") result!: DashboardResult;

  @ViewChild("chart") chartView!: ChartComponent;
  @ViewChild("chartcontainer", {read: ElementRef}) chartContainer!: ElementRef

  public series!: ApexAxisChartSeries;
  public chart: ApexChart;
  public xaxis!: ApexXAxis;
  public yaxis!: ApexYAxis;
  public stroke: ApexStroke;
  public markers!: ApexMarkers;
  public legend: ApexLegend;
  public grid: ApexGrid;
  public tooltip!: ApexTooltip;
  public fill!: ApexFill;

  constructor(@Inject(LOCALE_ID) private localeId: string) {
    this.chart = {
      type: "line",
      height:  200,
      zoom: { enabled: false },
      toolbar: { show: false },
      width: '95%',
      offsetX: -10
    };

    this.stroke = {
      curve: "smooth", // "straight"
      width: [2],
      colors: [COLORS.LL_WHITE]
    };

    this.grid = {
      yaxis: {
        lines: { show: true }
      },
      xaxis: {
        lines: { show: true }
      },
      borderColor: `${COLORS.LL_WHITE}20`,  
    };

    this.legend = {
      show: false
    };
  }

  ngOnInit(): void {
    this.series = [
      {
        name: "data",
        type: "line",
        data: this.result.values.map((value: DashboardResultValue) => [value.time, value.doubleValue ?? value.intValue]) as [number, number | null][],
      }
    ];

    this.tooltip = {
      enabled: false,
      custom: ({series, seriesIndex, dataPointIndex, w}) => {
        let coordinates = w.globals.initialSeries[seriesIndex].data[dataPointIndex];
        return `<div class="evolution-tooltip">
                    <span>
                        <b>${formatDate(new Date(coordinates[0]), 'dd.MM.yyyy', this.localeId)}:</b>
                        ${formatNumber(series[seriesIndex][dataPointIndex], this.localeId, '1.0-2')}
                  </span>
              </div>`
      }
    };

    this.markers = {
      size: 0,
      strokeWidth: 0,
      // Set color of markers based on value risk level
      discrete: this.result.values.map((value: DashboardResultValue, index: number) => {
        return {
          seriesIndex: 0,
          dataPointIndex: index,
          fillColor: DashboardResultRiskLevel.isScaled(value.riskLevel) ? DashboardResultRiskLevel.toColor(value.riskLevel) : COLORS.LL_WHITE,
          strokeWidth: 0,
          size: 5,
          shape: "circle" // "circle" | "square" | "rect"
        }
      })
    };


    // Set line gradient color based on value risk level data points -> offset is 0 - 100 scale, datapoints in epoch time so we must rescale
    let timeMin = this.result.values[0].time;
    let timeMax = this.result.values[this.result.values.length - 1].time
    let calculateOffset = (time: number) => {
      return (time - timeMin) / ( (timeMax - timeMin) / 100 );
    };
    this.fill = {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        colorStops: this.result.values.map((value: DashboardResultValue, index: number) => {
          return {
            offset: calculateOffset(value.time),
            color: DashboardResultRiskLevel.isScaled(value.riskLevel) ? DashboardResultRiskLevel.toColor(value.riskLevel) : COLORS.LL_WHITE,
            opacity: 1
          };
        })
      }
    }

    this.xaxis = {
      labels: {
        trim: false,
        style: {
          colors: COLORS.LL_WHITE,
          fontSize: '8px',
          fontFamily: 'ObjectSans'
        },
        offsetY: -3,
        formatter: (value, timestamp, opts) => {
          if (timestamp && typeof timestamp === 'number') {
            return opts.dateFormatter(new Date(timestamp), 'dd.MM.yyyy')
          }
          return '';
        }
      },
      tooltip: {
        enabled: false
      },
      tickAmount: 6,
      axisTicks: {
        show: true,
        color: COLORS.LL_WHITE,
      },
      axisBorder: {
        show: true,
        color: COLORS.LL_WHITE
      }
    };

    this.yaxis = {
      
      axisTicks: {
        show: true,
        offsetX: 2 + 5, 
        offsetY: 1,
        width: 4,
        color: COLORS.LL_WHITE
      },
      axisBorder: {
        show: true,
        color: COLORS.LL_WHITE,
        offsetX: -5
      },
      labels: {
        offsetX: 0 + 5,
        style: {
          colors: [COLORS.LL_WHITE],
          fontSize: '9px',
          fontFamily: 'ObjectSans'
        },
        formatter: (value) => { return formatNumber(value, this.localeId, '1.0-2') }
      },
      tooltip: { enabled: false },
      title: {
        text: this.result.unit,
        style: {
          color: COLORS.LL_WHITE,
          fontSize: '8px',
          fontFamily: 'ObjectSans'
        },
        rotate: 0,
        offsetX: 20
      }
    };
  }

  ngAfterViewInit(): void {
    // take max height of container but keep some spacing (40) for the title
    this.chart.height = `${this.chartContainer.nativeElement.offsetHeight - 40}px`;

    this.yaxis.title!.offsetY = 30 - this.chartContainer.nativeElement.offsetHeight / 2
  }

}
