<main>
    <ll-header [category]="selectedGroup" [page]="this.selectedGroup == 'LL_AGE' ? 'age' : 'score'" [navigateToSummary]="true" [sendCloseEvent]="true"></ll-header>
    <section class="content">
        <ll-category-nav-bar [selectedCategory]="selectedGroup"></ll-category-nav-bar>
        <div class="col ll-column-ctr">
            <div class="overflow-mask values ll-dark-shading" *ngIf="selectedGroup">
                <div class="ll-overflow-y">
                    <div *ngIf="((data | async)?.groups | filterTags:['DASHBOARD_SUMMARY']:false) as filtered">
                        <div *ngIf="selectedGroup == 'LL_SCORE' && filtered[0] as safeData">
                            <div class="ll-card-styled">
                                <p><u>{{'PAGE.AGE_AND_SCORE.EVOLUTIE_LEADLIFE_SCORE' | translate}}</u></p>
                                <ll-leadlife-score-graph [resultGroup]="safeData"></ll-leadlife-score-graph>
                            </div>
                            <div class="ll-card-styled">
                                <p><u>{{'PAGE.AGE_AND_SCORE.LEADLIFE_SCORES' | translate}}</u></p>
                                <ll-score-overview-graph [data]="safeData"></ll-score-overview-graph>
                            </div>
                        </div>
                        <div *ngIf="selectedGroup == 'LL_AGE' && filtered[0] as safeData" class="ll-card-styled">
                            <p><u>{{'PAGE.AGE_AND_SCORE.LEADLIFE_AGE' | translate}}</u></p>
                            <ll-age-evolution [data]="safeData"></ll-age-evolution>
                            <div *ngFor="let grp of safeData.groups; let i = index;">
                                <ll-textual *ngIf="DashboardResultGroup.getLatestScore(grp) as score"
                                    [label]="grp.name"
                                    [value]="(score.leadlifeAge | number:'1.1-1') + ' ' + ('PAGE.AGE_AND_SCORE.YEAR' | translate)"
                                    [color]="DashboardResultRiskLevel.toColor(score.riskLevel)"
                                ></ll-textual>
                                <hr *ngIf="i != safeData.groups.length - 1">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
