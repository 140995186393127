<main>
    <ll-header [category]="category" [page]="'data'" [navigateToSummary]="true" [sendCloseEvent]="true"></ll-header>
    <section class="content">
        <ll-category-nav-bar [selectedCategory]="category"></ll-category-nav-bar>
        <div class="categories">
            <div class="overflow">
                <div class="ll-card" *ngFor="let group of categoryGroup?.groups" (click)="selectGroup(group)" [class.selected]="selectedGroup?.tag == group.tag">
                    <p>{{ group.name }}</p>
                </div>
            </div>
        </div>
        <div class="col ll-column-ctr">
            <div class="overflow-mask values ll-dark-shading" *ngIf="selectedGroup">
                <div class="ll-overflow-y">
                    <div *ngFor="let subgroup of selectedGroup.groups" class="ll-card-styled">
                        <h2 *ngIf="subgroup.results && subgroup.results.length > 0">{{ subgroup.name }}</h2>
                        <div class="results">
                            <div *ngFor="let result of subgroup.results; let i = index;" class="result ll-row-ctr" llResultEvolution [llResultEvolutionType]="category" [llResultEvolutionGroup]="subgroup" [llResultEvolutionResult]="result">
                                <p>{{ result.name }}<ll-value-debugger [result]="result"></ll-value-debugger></p>
                                <p *ngIf="category == 'blood' && (DashboardResult.getLatestValue(result) | extractResultValue) as value">{{ value }}</p>
                                <p *ngIf="category == 'blood' && (DashboardResult.getLatestValue(result) | extractResultValue) as value">{{ result.unit }}</p>
                                <p class="keyword" *ngIf="DashboardResult.getLatestValue(result)?.riskLevel == RiskLevel.NOT_APPLICABLE else circle" [style.color]="DashboardResult.getLatestValue(result) | extractResultColor" [class.nodisplay]="category == 'blood'">
                                    {{ DashboardResult.getLatestValue(result)?.stringValue && DashboardResult.getLatestValue(result)?.stringValue?.trim() != '' ? DashboardResult.getLatestValue(result)?.stringValue : '-' }}
                                </p>
                                <ng-template #circle>
                                    <ll-risk-level-circle [color]="DashboardResult.getLatestValue(result) | extractResultColor" *ngIf="DashboardResult.getLatestValue(result)?.riskLevel"></ll-risk-level-circle>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</main>
