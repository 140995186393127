import { formatNumber } from '@angular/common';
import { Component, ElementRef, Inject, Input, LOCALE_ID, OnInit, ViewChild } from '@angular/core';
import { COLORS } from 'src/app/constants/Colors';
import { DashboardResultGroup, DashboardResultRiskLevel, DashboardScoreResult } from 'src/app/model/DashboardResult';
import { ApexAxisChartSeries, ApexChart, ApexGrid, ApexLegend, ApexMarkers, ApexStroke, ApexTooltip, ApexXAxis, ApexYAxis, ChartComponent } from 'ng-apexcharts';
// Import ApexOptions to get ApexFill because of bug (ApexFill types from ng-apexcharts are
// not working as expected for the gradient) -> https://github.com/apexcharts/ng-apexcharts/issues/207
import { ApexOptions } from 'apexcharts';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'll-age-evolution',
  templateUrl: './llage-evolution.component.html',
  styleUrls: []
})
export class LLAgeEvolutionComponent implements OnInit {

  @Input('data') data!: DashboardResultGroup;

  @ViewChild("chart") chartView!: ChartComponent;
  @ViewChild("chartcontainer", {read: ElementRef}) chartContainer!: ElementRef

  public series: ApexAxisChartSeries | null = null;
  public chart!: ApexChart;
  public xaxis!: ApexXAxis;
  public yaxis!: ApexYAxis;
  public stroke!: ApexStroke;
  public markers!: ApexMarkers;
  public legend: ApexLegend | null = null;
  public grid!: ApexGrid;
  public tooltip!: ApexTooltip;
  public fill!: ApexFill;

  constructor(
    @Inject(LOCALE_ID) private localeId: string,
    private translateService: TranslateService
  ) {}

  private getLegendLayout(background: string, text: string): string {
    return `<span style="display: inline-block; margin: 0 3px 3px 0; height: 2px; width: 14px; background: ${background};"></span><span>${text}</span>`;
  }

  ngOnInit(): void {
    this.chart = {
      type: "line",
      zoom: { enabled: false },
      toolbar: { show: false },
      width: '100%',
      height: 200,
      offsetX: -10,
      offsetY: -20,
      foreColor: COLORS.LL_WHITE
    };

    this.stroke = {
      curve: "smooth", // "straight"
      width: [2, 2, 2]
    };

    this.grid = {
      yaxis: {
        lines: { show: true }
      },
      xaxis: {
        lines: { show: true }
      },
      borderColor: `${COLORS.LL_WHITE}20`,  
    };

    this.translateService.get(['COMPONENT.LL_AGE_EVOLUTION.LEADLIFE_AGE', 'COMPONENT.LL_AGE_EVOLUTION.LEADLIFE_GOAL']).subscribe((translations) => {
      this.legend = {
        show: true,
        position: 'bottom',
        markers: {
          width: 0,
          height: 0
        },
        itemMargin: {
          horizontal: 5,
          vertical: 0
        },
        customLegendItems: [
          this.getLegendLayout('linear-gradient(90deg, rgba(255,96,96,1) 0%, rgba(229,180,17,1) 40%, rgba(161,197,103,1) 100%);', translations['COMPONENT.LL_AGE_EVOLUTION.LEADLIFE_AGE']),
          this.getLegendLayout(COLORS.LL_RISK_GREEN_LIGHT, translations['COMPONENT.LL_AGE_EVOLUTION.LEADLIFE_GOAL'])
        ],
        fontSize: '10px',
        fontFamily: 'ObjectSans',
        offsetY: 4
      };

      this.series = [
        {
          name: translations['COMPONENT.LL_AGE_EVOLUTION.LEADLIFE_AGE'],
          type: "line",
          data: this.data.scoreResult.map((value: DashboardScoreResult) => [value.time, value.leadlifeAge]) as [number, number | null][],
        },
        {
          name: translations['COMPONENT.LL_AGE_EVOLUTION.LEADLIFE_GOAL'],
          type: "line",
          color: COLORS.LL_RISK_GREEN_LIGHT,
          data: this.data.scoreResult.map((value: DashboardScoreResult) => [value.time, value.goalAge]) as [number, number | null][],
        }
      ];
    })

    this.tooltip = {
      enabled: false,
    };

    this.markers = {
      size: 2,
      strokeWidth: 0,
      // Set color of markers based on value risk level
      discrete: this.data.scoreResult.map((value: DashboardScoreResult, index: number) => {
        return {
          seriesIndex: 0,
          dataPointIndex: index,
          fillColor: DashboardResultRiskLevel.isScaled(value.riskLevel) ? DashboardResultRiskLevel.toColor(value.riskLevel) : COLORS.LL_WHITE,
          strokeWidth: 0,
          size: 5,
          shape: "circle" // "circle" | "square" | "rect"
        }
      })
    };

    // Set line gradient color based on value risk level data points -> offset is 0 - 100 scale, datapoints in epoch time so we must rescale
    let timeMin = this.data.scoreResult[0].time;
    let timeMax = this.data.scoreResult[this.data.scoreResult.length - 1].time
    let calculateOffset = (time: number) => {
      return (time - timeMin) / ( (timeMax - timeMin) / 100 );
    };
    this.fill = {
      type: ['gradient', 'solid', 'solid'],
      gradient: {
        shadeIntensity: 1,
        colorStops: this.data.scoreResult.map((value: DashboardScoreResult, index: number) => {
          return {
            offset: calculateOffset(value.time),
            color: DashboardResultRiskLevel.isScaled(value.riskLevel) ? DashboardResultRiskLevel.toColor(value.riskLevel) : COLORS.LL_WHITE,
            opacity: 1
          };
        })
      }
    }

    this.xaxis = {
      labels: {
        trim: false,
        style: {
          colors: COLORS.LL_WHITE,
          fontSize: '8px',
          fontFamily: 'ObjectSans'
        },
        offsetY: -3,
        formatter: (value, timestamp, opts) => {
          if (timestamp && typeof timestamp === 'number') {
            return opts.dateFormatter(new Date(timestamp), 'dd.MM.yyyy')
          }
          return '';
        }
      },
      tooltip: {
        enabled: false
      },
      tickAmount: 6,
      axisTicks: {
        show: true,
        color: COLORS.LL_WHITE,
        height: 6,
        borderType: 'solid'
      },
      axisBorder: {
        show: true,
        color: COLORS.LL_WHITE
      }
    };

    this.yaxis = {
      axisTicks: {
        show: true,
        offsetX: 2 + 5, 
        offsetY: 1,
        width: 4,
        color: COLORS.LL_WHITE
      },
      axisBorder: {
        show: true,
        color: COLORS.LL_WHITE,
        offsetX: -5
      },
      labels: {
        offsetX: 0 + 5,
        style: {
          colors: [COLORS.LL_WHITE],
          fontSize: '9px',
          fontFamily: 'ObjectSans'
        },
        formatter: (value) => { return formatNumber(value, this.localeId, '1.0-2') }
      },
      tooltip: { enabled: false }
    };
  }

  ngAfterViewInit(): void {
    // take max height of container but keep some spacing (40) for the title
    // this.chart.height = `${this.chartContainer.nativeElement.offsetHeight - 40}px`;
  }
}
