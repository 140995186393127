<div class="categories">
    <div #container class="overflow">
        <div class="ll-card" id="LL_SCORE" (click)="selectCategory('SCORE_EVOLUTION')" [class.selected]="selectedCategory === 'LL_SCORE'">
            <p>{{ 'COMMON.CATEGORY.LL_SCORE' | translate }}</p>
        </div>
        <div *ngIf="!ageHidden" class="ll-card" id="LL_AGE" (click)="selectCategory('AGE_EVOLUTION')" [class.selected]="selectedCategory === 'LL_AGE'">
            <p>{{ 'COMMON.CATEGORY.LL_AGE' | translate }}</p>
        </div>
        <div class="ll-card" id="body" (click)="selectCategory('CATEGORY', 'body')" [class.selected]="selectedCategory === 'body'">
            <p>{{ 'COMMON.CATEGORY.BODY' | translate }}</p>
        </div>
        <div class="ll-card" id="move" (click)="selectCategory('CATEGORY', 'move')" [class.selected]="selectedCategory === 'move'">
            <p>{{ 'COMMON.CATEGORY.MOVE' | translate }}</p>
        </div>
        <div class="ll-card" id="stress" (click)="selectCategory('CATEGORY', 'stress')" [class.selected]="selectedCategory === 'stress'">
            <p>{{ 'COMMON.CATEGORY.STRESS' | translate }}</p>
        </div>
        <div class="ll-card" id="health" (click)="selectCategory('CATEGORY', 'health')" [class.selected]="selectedCategory === 'health'">
            <p>{{ 'COMMON.CATEGORY.HEALTH' | translate }}</p>
        </div>
        <div class="ll-card" id="food" (click)="selectCategory('CATEGORY', 'food')" [class.selected]="selectedCategory === 'food'">
            <p>{{ 'COMMON.CATEGORY.FOOD' | translate }}</p>
        </div>
        <div class="ll-card" id="sleep" (click)="selectCategory('CATEGORY', 'sleep')" [class.selected]="selectedCategory === 'sleep'">
            <p>{{ 'COMMON.CATEGORY.SLEEP' | translate }}</p>
        </div>
        <div class="ll-card" id="motivation" (click)="selectCategory('CATEGORY', 'motivation')" [class.selected]="selectedCategory === 'motivation'">
            <p>{{ 'COMMON.CATEGORY.MOTIVATION' | translate }}</p>
        </div>
        <div class="ll-card" id="dna" (click)="selectCategory('RAW_DATA', 'dna')" [class.selected]="selectedCategory === 'dna'">
            <p>{{ 'COMMON.CATEGORY.DNA' | translate }}</p>
        </div>
        <div class="ll-card" id="blood" (click)="selectCategory('RAW_DATA', 'blood')" [class.selected]="selectedCategory === 'blood'">
            <p>{{ 'COMMON.CATEGORY.BLOOD' | translate }}</p>
        </div>
    </div>
</div>